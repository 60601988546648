.label-file-input {
    padding: 7px 10px;
    font-size: 0.8125rem;
    border-radius: 4px;
    color: white;
    border: 1px solid #3f51b5 !important;
    background-color: #3f51b5 !important;

    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.inlineButton {
    color: white !important;
    background-color: #3F51B5 !important;
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }
  .inlineButton:hover {
    background-color: #626eb1 !important;
  }

.label-file-input-disible {
    padding: 7px 10px;
    font-size: 0.8125rem;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.26);
    border: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;

    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fixed-input {
    position: relative !important;
    overflow: hidden !important;
}

.fixed-upload-file {
    top: 0;
    position: absolute;
    visibility: hidden;
}

.ipf{
    margin-left: 12px;
}
.ag-theme-balham .multiline .ag-header-cell-label .ag-header-cell-text {
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
  height: auto;
  line-height: 1.5em;
}
.ag-theme-balham .ag-header-cell,
.ag-theme-balham .ag-header-group-cell {
  line-height: 32px;
  padding-left: 6px;
  padding-right: 0px;
}
.pdf:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.excel:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.ag-theme-balham .ag-header-cell,
.ag-theme-balham .ag-header-group-cell {
  line-height: 32px;
  padding-left: 6px;
  padding-right: 0px;
}

.ag-theme-balham .ag-header-cell.header-checkbox {
  padding-left: 12px;
}
